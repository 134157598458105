import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import VueProgressBar from 'vue-progressbar'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Axios from 'axios'
import store from './store/index'
import VueElementLoading from 'vue-element-loading'

import styles from './assets/css/style.css'

Vue.config.productionTip = false

Vue.use(VueToast, {
    position: 'bottom-left'
})

Vue.prototype.$http = Axios;
Vue.prototype.$axios = Axios;
Axios.defaults.withCredentials = true

// Axios.defaults.headers.common['Accept'] = 'application/json'
// Axios.defaults.headers.common['Content-Type'] = 'application/json'
Axios.defaults.baseURL = 'https://www.checkliste-sterbefall.de/backend/api/'
// Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// Progress bar Options
const options = {
    color: '#9EC685',
    failedColor: '#d32f2f',
    thickness: '5px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    location: 'top',
    inverse: false
}

Vue.use(VueProgressBar, options)
Vue.component('VueElementLoading', VueElementLoading)

new Vue({
    render: h => h(App),
    vuetify,
    store,
    styles
}).$mount('#app')