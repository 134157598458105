import axios from 'axios'
import Csrf from './../apis/Csrf'

export default {
    // Login User
    async setEmail({ commit }, data) {
        await Csrf.getCookie()

        return new Promise((resolve, reject) => {
            axios({ url: '/user', data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}