import axios from 'axios'

axios.defaults.withCredentials = true
const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['Authorization'] = token
}
axios.defaults.headers.common['Accept'] = 'application/json'

let Api = axios.create({
    baseURL: 'https://www.checkliste-sterbefall.de/backend/api/'
})

export default Api;